<template>
    <div>
        <van-nav-bar
            title="我的申请 - 合同"
            left-arrow
            @click-left="onClickLeft"
            />
          
            
            <div class="lineDiv">
                <div class="line"></div>
                <span>电费折扣讯息</span>
            </div>
            <div class="contractDiv" @click="toContract(1)">合同预览</div>
            <van-popup v-model="showContract" position="bottom" >
                <pdf            
                :src="url"
                :page="pageNum"
                @progress="loadedRatio = $event"
                @page-loaded="pageLoaded($event)"
                @num-pages="pageTotalNum=$event"
                @error="pdfError($event)"
                @link-clicked="page = $event"
                ></pdf>
                <div style="display:flex;justify-content: space-around;">
                    <button type="primary" @click.stop="prePage">上一页</button>
                     <button type="primary" @click.stop="nextPage">下一页</button>
                </div>
               

            </van-popup> 
            <a class="contractDiv"  href="/static/contract.pdf" download="浙江售电公司与电力用户购售电合同（2023年版）.pdf">下载合同模板</a>   
            <!-- <div class="contractDiv" @click="toContract(3)">上传合同</div>   -->
            <van-uploader v-model="fileList" :after-read="afterRead" accept=".pdf" >
                <div class="contractDiv contractText"  :max-count="1">上传文件</div>
            </van-uploader>  
    </div>
</template>
<script>
import pdf from 'vue-pdf'
import {uploads} from '../../../network/uploder'
import {getContract} from '../../../network/check'
    export default{
        name:'MeasureContract',
        data(){
            return{
                showContract:false,
        
                url: '/static/contract.pdf',
                pageNum: 1,
                pageTotalNum: 1,
                // 加载进度
                loadedRatio: 0,
                curPageNum: 0,
                goPageNum: 1,
                fileList:[]
            }
        },
        components:{pdf},
        methods:{
            onClickLeft(){
                this.$router.back()
            },
            toContract(index){
                switch (index) {
                    case 1:
                        this.showContract = !this.showContract;
                        break;
                    case 2:
                        
                        break;
                    case 3:
                        this.$toast.fail('暂不能上传合同,请谅解');
                        break;
                    default:
                        break;
                }
            },
            prePage() {
            var page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
            },
            // 下一页函数
            nextPage() {
            var page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
            },
              // 页面加载回调函数，其中e为当前页数
            pageLoaded(e) {
            this.curPageNum = e
            },
            // 其他的一些回调函数。
            pdfError(error) {
            console.error(error)
            },
            afterRead(file) {
                
                this.$toast.loading({
                        message: '上传中...',
                        forbidClick: false,
                        duration:0
                });
                let formData = new FormData()
                    formData.append('files', file.file);
                    formData.append('subPath','contract');
                    formData.append('resourceHandler','/contract/');
                    formData.append('basePath','contract');
                uploads(formData).then((res)=>{
                    this.$toast.clear();
                    if(res.data.success){
                        getContract(this.$route.query.id,res.data.data[0].url).then((data)=>{
                           
                            if(data.success){
                                this.$toast.fail(data.msg);
                            }else{
                                this.$toast.fail('上传失败了');
                            }
                        })
                       
                    }else{
                        this.$toast.fail('上传失败');
                    }
                })
               
            },
        },
        mounted() {
            document.querySelector('body').setAttribute('style','background-color:#ffffff');
                 // 上一页函数，
        },
    }
</script>
<style scoped>
.lineDiv{
    display: flex;
    align-items: center;
    
}
.lineDiv span{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 35px 20px 28px 0px;
}
.line{
    width: 10px;
    height: 34px;
    background: #06B2B2;
    border-radius: 5px;
    margin: 32px 37px 26px 26px;
    display: inline-block;
}
.contractDiv{
    width: 616px;
    line-height: 80px;
    background: #06B2B2;
    border-radius: 10px;
    text-align: center;
    margin: 28px auto;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: block;
   
}
.contractText{
    margin-left: 10%;
}

</style>
