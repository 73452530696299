import axios from './axios2'

//文件上传
export function uploads(data) {
  let token = sessionStorage.getItem('token');
				
    return axios({
      url: '/api/power/info/uploads',
      method:'post',
      processData:false,
      headers:{'x-auth-token':token},
      data:data
    })
}
