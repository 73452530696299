import originAxios from 'axios'



export default function axios(option) {
	return new Promise((resolve, reject) => {
		// 1.创建axios的实例
		const instance = originAxios.create({
		// baseURL:'https://power.smegold.com/admin/',
		//baseURL:'https://power-t.smegold.com/admin/',
			baseURL:'/admin/',
			baseURL:'',
			timeout: 5000,
		});

		instance(option).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}

